import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <nav className="py-5 inset-x-0  z-40">
      <div className="max-w-7xl relative mx-auto flex justify-between items-center px-1 lg:px-2">
        <Link to="/"><img src={logo} alt="logo" className="object-cover h-7 lg:h-12" /></Link>
        <button onClick={toggleMenu} className="block lg:hidden focus:outline-none">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M4 6h16a1 1 0 110 2H4a1 1 0 110-2zm0 5h16a1 1 0 110 2H4a1 1 0 110-2zm0 5h16a1 1 0 110 2H4a1 1 0 110-2z" />
          </svg>
        </button>
        <ul className={`lg:flex lg:space-x-7 lg:items-center ${isMenuOpen ? 'fixed top-0 left-0 h-screen w-full bg-white z-20 flex-col' : 'hidden lg:flex lg:flex-row lg:space-x-7'}`}>
          {isMenuOpen && (
            <li className="lg:hidden text-right	pt-5 px-1">
              <button onClick={toggleMenu} className="focus:outline-none ">
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path
                    fillRule="evenodd"
                    d="M6.7 6.7a1 1 0 011.4 0L12 10.59l3.9-3.9a1 1 0 111.4 1.42l-3.88 3.9 3.88 3.9a1 1 0 01-1.42 1.42l-3.9-3.88-3.9 3.88a1 1 0 01-1.42-1.42l3.9-3.88-3.9-3.9a1 1 0 010-1.42z" />
                </svg>
              </button>
            </li>
          )}
          <li><Link to="/services" className="block lg:inline-block py-4 lg:py-2 px-2 lg:py-0  flex font-medium	deca">
            <span className='block lg:hidden px-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
            </svg>
            </span>Services</Link>
          </li>
          <li><Link to="/About-us" className="block lg:inline-block py-4 lg:py-2 px-2 lg:py-0 flex deca font-medium	">
            <span className='block lg:hidden px-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
            </svg>
            </span>
            About Us </Link></li>
          <li><Link to="/why-choose-us" className="block lg:inline-block py-4 lg:py-2 px-2 lg:py-0 flex font-medium	deca">
            <span className='block lg:hidden px-2'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
              </svg>
            </span>
            Why Choose Us</Link></li>
          <li><Link to="/testimonials" className="block lg:inline-block py-4 lg:py-2 px-2 lg:py-0 flex font-medium	deca">
            <span className='block lg:hidden px-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
            </svg>
            </span>
            Testimonials</Link></li>
          {/* Add more nav items as needed */}
        </ul>
      </div>
    </nav>


  );
}

export default Nav;
